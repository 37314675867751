import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Dashboard from '../../routes/dashboard/Dashboard'
import Login from '../../routes/login/Login'
import { BASENAME } from '../../utils/config'
import { AuthInterceptor, GALogger, RequireAuth } from './components'

const Router = () => (
  <BrowserRouter basename={BASENAME}>
    <GALogger />
    <AuthInterceptor />
    <Routes>
      <Route path='*' element={<Navigate to='/' />} />
      <Route
        path='/'
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route path='/login' element={<Login />} />
    </Routes>
  </BrowserRouter>
)

export default Router
