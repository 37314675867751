import { Container } from '@mui/material'
import { Column } from 'material-table'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Table, TableFilterProps } from '../../components/dashboard/Table'
import { UserStatusSelect } from '../../components/dashboard/UserStatusSelect'
import PageBase from '../../components/pageBase/PageBase'
import { ApiResponseBase, ApiResponseError } from '../../types/api/base'
import { UserStatus, WaitlistUser } from '../../types/api/waitlist/get'
import { changeUserStatus, getWaitlist } from '../../utils/api/waitlist'

const Dashboard = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const updateUserStatus = useCallback(
    async (id: string, status: UserStatus) => {
      enqueueSnackbar(
        t('dashboard.userStatus.updatingStatusMessage') as string,
        {
          variant: 'info',
          autoHideDuration: 2300
        }
      )
      try {
        const response = await changeUserStatus(id, status)
        await new Promise<void>((res) => {
          setTimeout(res, 300)
        })
        if (response.error) throw response.error
        enqueueSnackbar(
          t('dashboard.userStatus.updatedStatusMessage') as string,
          {
            variant: 'success',
            autoHideDuration: 2300
          }
        )
      } catch (err) {
        const error = err as ApiResponseError
        enqueueSnackbar(t('errors.genericError', { statusCode: error.code }), {
          variant: 'error'
        })
        throw err
      }
    },
    [enqueueSnackbar, t]
  )

  const renderColumns = useCallback((): Column<WaitlistUser>[] => {
    const columns: Column<WaitlistUser>[] = [
      { title: t('dashboard.table.columns.id'), field: '_id' },
      { title: t('dashboard.table.columns.firstName'), field: 'firstName' },
      { title: t('dashboard.table.columns.lastName'), field: 'lastName' },
      {
        title: t('dashboard.table.columns.employerName'),
        field: 'employerName'
      },
      { title: t('dashboard.table.columns.city'), field: 'city' },
      { title: t('dashboard.table.columns.invitations'), field: 'invitations' },
      {
        title: t('dashboard.table.columns.status'),
        field: 'status',
        render: (rowData: WaitlistUser) => (
          <UserStatusSelect
            status={rowData.status}
            // eslint-disable-next-line no-underscore-dangle
            onSubmit={(status) => updateUserStatus(rowData._id, status)}
          />
        )
      }
    ]
    return columns
  }, [t, updateUserStatus])

  const fetchWaitlist = useCallback(
    async (
      filter: TableFilterProps
    ): Promise<ApiResponseBase<WaitlistUser[]>> => {
      const query: { limit: number; lastId?: string } = { limit: filter.limit }
      if (filter.lastId) query.lastId = filter.lastId
      return getWaitlist(query)
    },
    []
  )

  return (
    <PageBase hideFooterOnMobile>
      <Container>
        <Table fetchData={fetchWaitlist} renderColumns={renderColumns} />
      </Container>
    </PageBase>
  )
}

export default Dashboard
