import { Box, styled } from '@mui/material'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 2rem;
  left: 5rem;

  ${({ theme }) => theme.breakpoints.down('tablet')} {
    position: unset;
    margin: 2rem 0;
    align-items: center;
  }
`
