import {
  CheckCircle as Removed,
  DoDisturb as Rejected,
  PendingActions as Pending,
  ScheduleSend as Accepted
} from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip
} from '@mui/material'
import i18next from 'i18next'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getLabelFromStatus, UserStatus } from '../../types/api/waitlist/get'

export interface TableFilterProps {
  lastId?: string
  limit: number
}

interface UserStatusProps {
  status: UserStatus
  // eslint-disable-next-line no-unused-vars
  onSubmit: (status: UserStatus) => Promise<any>
}

const Icons: Record<UserStatus, React.FC> = {
  [UserStatus.PENDING]: Pending,
  [UserStatus.ACCEPTED]: Accepted,
  [UserStatus.REMOVED]: Removed,
  [UserStatus.REJECTED]: Rejected
}

const userStatusOptions: Record<UserStatus, string> = {
  [UserStatus.PENDING]: i18next.t('dashboard.userStatus.labels.pending'),
  [UserStatus.ACCEPTED]: i18next.t('dashboard.userStatus.labels.accepted'),
  [UserStatus.REMOVED]: i18next.t('dashboard.userStatus.labels.removed'),
  [UserStatus.REJECTED]: i18next.t('dashboard.userStatus.labels.rejected')
}

export const UserStatusSelect = (props: UserStatusProps) => {
  const { t } = useTranslation()
  const { status, onSubmit } = props
  const [userStatus, setUserStatus] = useState<UserStatus>(status)
  const [dialogStatus, setDialogStatus] = useState<UserStatus>(userStatus)
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const radioGroupRef = useRef<HTMLElement | null>(null)

  const Icon = useMemo(() => Icons[userStatus], [userStatus])
  const handleEntering = useCallback(() => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }, [])
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDialogStatus(Number(event.target.value) as UserStatus)
    },
    []
  )
  const closeDialog = useCallback(() => {
    setDialogOpen(false)
    setDialogStatus(userStatus)
  }, [userStatus])
  const handleConfirmation = useCallback(
    () =>
      onSubmit(dialogStatus).then(() => {
        setUserStatus(dialogStatus)
        setDialogOpen(false)
      }),
    [dialogStatus, onSubmit]
  )

  return (
    <>
      {isDialogOpen ? (
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth='xs'
          TransitionProps={{ onEntering: handleEntering }}
          open={isDialogOpen}
        >
          <DialogTitle>{t('dashboard.userStatus.ctaTitle')}</DialogTitle>
          <DialogContent dividers>
            <RadioGroup
              ref={radioGroupRef}
              name='user-status'
              value={dialogStatus}
              onChange={handleChange}
            >
              {Object.entries(userStatusOptions).map(([statusVal, label]) => (
                <FormControlLabel
                  value={statusVal}
                  key={statusVal}
                  control={<Radio />}
                  label={label}
                />
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeDialog}>
              {t('actions.cancel')}
            </Button>
            <Button variant='contained' onClick={handleConfirmation}>
              {t('actions.save')}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <Grid container justifyContent='center' alignItems='center'>
        <Grid
          item
          style={{ cursor: 'pointer' }}
          onClick={() => setDialogOpen(true)}
        >
          <Tooltip
            title={
              t(
                `dashboard.userStatus.values.${getLabelFromStatus(
                  userStatus
                ).toLowerCase()}`
              ) as string
            }
          >
            <Icon />
          </Tooltip>
        </Grid>
      </Grid>
    </>
  )
}
