import { Paper } from '@material-ui/core'
import MaterialTable, { Column, QueryResult } from 'material-table'
import React, { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ApiResponseBase } from '../../types/api/base'
import { WaitlistUser } from '../../types/api/waitlist/get'

export interface TableFilterProps {
  lastId?: string
  limit: number
}

interface TableProps {
  renderColumns: () => Column<WaitlistUser>[]
  fetchData: (
    // eslint-disable-next-line no-unused-vars
    filter: TableFilterProps
  ) => Promise<ApiResponseBase<WaitlistUser[]>>
}

export const Table = (props: TableProps) => {
  const { t } = useTranslation()
  const { renderColumns, fetchData } = props
  const tableRef: React.RefObject<MaterialTable<WaitlistUser>> =
    useRef<MaterialTable<WaitlistUser>>(null)
  // const [waitlistData, setWaitlistData] = useState<WaitlistUser[]>([])

  const columns = useMemo(() => renderColumns(), [renderColumns])

  // FIXME: RIGHT NOW WE DO NOT HAVE PAGINATION (we need Total number of elements so we can calculate totalPages from pageSize)
  const getData = useCallback(
    async (query: { pageSize: number }): Promise<QueryResult<WaitlistUser>> =>
      fetchData({
        limit: query.pageSize,
        lastId: undefined
      }).then((res) => ({
        data: res.data ?? [],
        page: 0,
        totalCount: res.data?.length ?? 0
      })),
    [fetchData]
  )

  return (
    <Paper>
      <MaterialTable
        title={t('dashboard.table.title')}
        tableRef={tableRef}
        options={{
          exportButton: false,
          search: false,
          emptyRowsWhenPaging: false,
          sorting: false,
          pageSize: 100,
          paging: false,
          pageSizeOptions: [10, 20, 50, 100],
          paginationType: 'normal',
          draggable: true,
          initialPage: 0,
          showTitle: true,
          actionsColumnIndex: -1,
          maxBodyHeight: '70vh'
        }}
        columns={columns}
        data={getData}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          Container: (p) => <Paper {...p} elevation={0} />
        }}
      />
    </Paper>
  )
}
