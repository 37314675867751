import { Button, Grid } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import PageBase from '../../components/pageBase/PageBase'
import { sendLoginRequest } from '../../utils/api/login'
import AuthManager from '../../utils/auth-manager'

const Login = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isTokenValid = AuthManager.isTokenValid()

  const sendAuthReq = useCallback(async () => {
    const res = await sendLoginRequest()
    if (res.statusCode !== 202) {
      enqueueSnackbar(t('login.emailError'), {
        variant: 'error'
      })
      return console.error(t('login.emailError'))
    }
    enqueueSnackbar(t('login.sentEmail') as string, { variant: 'info' })
  }, [enqueueSnackbar, t])

  useEffect(() => {
    if (isTokenValid) navigate('/', { replace: true })
  }, [isTokenValid, navigate])

  return (
    <PageBase hideFooterOnMobile needsNavBar={false}>
      <Grid
        container
        style={{
          height: '100vh',
          alignItems: 'center'
        }}
      >
        <Grid
          container
          item
          xs={12}
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Button variant='contained' size='medium' onClick={sendAuthReq}>
            {t('login.loginCta')}
          </Button>
        </Grid>
      </Grid>
    </PageBase>
  )
}

export default Login
