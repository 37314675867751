import ReactGA from 'react-ga4'

import reportWebVitals from '../reportWebVitals'
import { GA_MEASUREMENT_ID } from '../utils/config'

ReactGA.initialize(GA_MEASUREMENT_ID)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(({ id, name, value }) => {
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    label: id, // id unique to current page load
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    nonInteraction: true // avoids affecting bounce rate
  })
})
