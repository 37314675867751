import jwtDecode, { JwtPayload } from 'jwt-decode'

interface TokenPayload extends JwtPayload {
  type: string
}

const getAuthToken = () => localStorage.getItem('token')

const AuthManager = {
  setToken: (token: string) => {
    localStorage.setItem('token', token)
  },

  isTokenValid: () => {
    const token = getAuthToken()
    if (!token) return false
    try {
      const decoded = jwtDecode<TokenPayload>(token)
      if (
        decoded.type !== 'PPWaitlistAdminAuth' ||
        decoded.iss !== 'PPWaitlist-Admin-Dashboard'
      )
        return false
      const { exp } = decoded
      const currentTime = Date.now() / 1000
      return exp! > currentTime
    } catch (err) {
      console.error(err)
      return false
    }
  },

  clearAuth: () => localStorage.clear()
}

export default AuthManager
