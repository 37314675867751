export interface GetList {
  lastId?: string
  limit: number
}

// eslint-disable-next-line no-shadow
export enum UserStatus {
  PENDING = 0,
  ACCEPTED = 1,
  REMOVED = 2,
  REJECTED = 3
}

type UserStatusLabel = 'PENDING' | 'ACCEPTED' | 'REMOVED' | 'REJECTED'

const StatusLabel: Record<UserStatus, UserStatusLabel> = {
  [UserStatus.PENDING]: 'PENDING',
  [UserStatus.ACCEPTED]: 'ACCEPTED',
  [UserStatus.REMOVED]: 'REMOVED',
  [UserStatus.REJECTED]: 'REJECTED'
}

export const getLabelFromStatus = (status: UserStatus): UserStatusLabel =>
  StatusLabel[status]

export interface WaitlistUser {
  _id: string
  firstName: string
  lastName: string
  employerName: string
  city: string
  invitations: number
  status: UserStatus
}
