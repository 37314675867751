import { Box, Button, Divider, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import Footer from '../footer/Footer'

export const Header = styled(Box)`
  height: 4.375rem;
  margin: 3rem 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 1.875rem;
    height: 3.125rem;
  }
`

export const Logo = styled('img')`
  height: 100%;
`
export const NavContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.688rem 0;

  ${({ theme }) => theme.breakpoints.down('tablet')} {
    display: none;
  }
`

export const NavButton = styled(Button)`
  margin: 0 0.5rem;
`

export const MenuButton = styled(IconButton)`
  display: none;

  ${({ theme }) => theme.breakpoints.down('tablet')} {
    display: flex;
  }
`

export const DrawerContainer = styled(Box)`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    223.77deg,
    ${({ theme }) => theme.palette.primary.light},
    ${({ theme }) => theme.palette.primary.dark}
  );
  padding: 1.5rem;
`

export const DrawerHeader = styled(Box)`
  height: 2.813rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const DrawerContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
`

export const DrawerButton = styled(Button)`
  color: white;
  border-color: white;

  :hover {
    border-color: white;
  }
`

export const DrawerDivider = styled(Divider)`
  width: 5rem;
  border-bottom-width: 2px;
  border-color: white;
  margin-bottom: 1.5rem;
`

export const DrawerFooter = styled(Footer)`
  position: unset;

  p {
    color: white;
  }
`
