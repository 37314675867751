import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Navigate, useLocation } from 'react-router-dom'

import AuthManager from '../../utils/auth-manager'

export const GALogger = () => {
  const location = useLocation()
  useEffect(() => {
    ReactGA.send('pageview')
  }, [location])

  return null
}

export const AuthInterceptor = () => {
  const location = useLocation()
  useEffect(() => {
    if (
      location.pathname.includes('/authentication') &&
      location.search.includes('?token=')
    ) {
      const urlSearchParams = new URLSearchParams(location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (params.token) AuthManager.setToken(params.token)
    }
  }, [location])

  return null
}

export const RequireAuth: React.FC<any> = ({ children }) => {
  const isAuthenticated = AuthManager.isTokenValid()
  return isAuthenticated ? children : <Navigate to='/login' replace />
}
