import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { Button, SwipeableDrawer } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import AuthManager from '../../utils/auth-manager'
import {
  DrawerButton,
  DrawerContainer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Header,
  Logo,
  MenuButton,
  NavButton,
  NavContainer
} from './components'

const Navbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setIsDrawerOpen(open)
    }

  const handleLogout = useCallback(() => {
    closeSnackbar()
    AuthManager.clearAuth()
    navigate('/login')
  }, [closeSnackbar, navigate])

  const actions = useCallback(
    (key: string) => (
      <>
        <Button
          style={{ color: '#fff' }}
          variant='text'
          size='small'
          onClick={handleLogout}
        >
          {t('header.nav.logout.cta')}
        </Button>
        <Button
          style={{ color: '#fff' }}
          variant='text'
          size='small'
          onClick={() => {
            closeSnackbar(key)
          }}
        >
          {t('actions.cancel')}
        </Button>
      </>
    ),
    [closeSnackbar, handleLogout, t]
  )

  const shouldLogOut = useCallback(() => {
    enqueueSnackbar(t('header.nav.logout.prompt'), {
      variant: 'info',
      action: actions
    })
  }, [actions, enqueueSnackbar, t])

  return (
    <Header>
      <Logo src='logo.png' />
      <NavContainer>
        <NavButton variant='contained' size='small' onClick={shouldLogOut}>
          {t('header.nav.logout.cta')}
        </NavButton>
      </NavContainer>
      <MenuButton color='primary' size='large' onClick={toggleDrawer(true)}>
        <MenuRoundedIcon />
      </MenuButton>
      <SwipeableDrawer
        anchor='right'
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <DrawerContainer>
          <DrawerHeader>
            <Logo src='./logo_white.png' />
            <MenuButton
              size='large'
              onClick={toggleDrawer(false)}
              sx={{ color: 'white' }}
            >
              <CloseRoundedIcon />
            </MenuButton>
          </DrawerHeader>
          <DrawerContent>
            <DrawerButton variant='text' size='large' onClick={shouldLogOut}>
              {t('header.nav.logout.cta')}
            </DrawerButton>
          </DrawerContent>
          <DrawerFooter />
        </DrawerContainer>
      </SwipeableDrawer>
    </Header>
  )
}

export default Navbar
