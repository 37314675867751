import { ApiResponseBase } from '../../types/api/base'
import { API_BASE_URL } from '../config'

const serializeParams = (params: object) => {
  const searchParams = new URLSearchParams()
  Object.keys(params).forEach((key) => {
    const paramValue = (params as any)[key]
    if (paramValue !== '' || paramValue.length > 0) {
      if (Array.isArray(paramValue)) {
        paramValue.forEach((itemValue) => {
          searchParams.append(key, itemValue)
        })
      } else searchParams.append(key, paramValue)
    }
  })
  return searchParams.toString()
}

export const call = async <T>(
  method: 'GET' | 'POST' | 'PATCH',
  path: string,
  data: object = {},
  needsAuth: boolean = false
): Promise<ApiResponseBase<T>> => {
  const hasBody = ['POST', 'PUT', 'PATCH'].includes(method)
  const url = new URL(path, API_BASE_URL)
  if (!hasBody) {
    url.search = serializeParams(data)
  }
  const json: ApiResponseBase<T> = {
    statusCode: 0
  }
  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  }
  if (needsAuth)
    headers.Authorization = `Bearer ${localStorage.getItem('token') ?? ''}`
  try {
    const res = await fetch(url.href, {
      method,
      mode: 'cors',
      headers,
      body: hasBody ? JSON.stringify(data) : undefined
    })
    if (!res.ok) {
      const error = await res.json()
      return {
        statusCode: res.status,
        error: { code: error.statusCode, message: error.message }
      }
    }
    const respData = await res.json().catch(() => null)
    return {
      statusCode: respData.statusCode || res.status,
      data: respData.data
    }
  } catch (err) {
    json.error = { code: 0, message: String(err) }
    throw json
  }
}
