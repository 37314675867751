import { createTheme, responsiveFontSizes } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { Shadows } from '@mui/material/styles/shadows'
import React from 'react'

import { LinkBehavior } from '../components/linkBehavior/LinkBehavior'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#7557D9',
      light: '#AD90EE',
      dark: '#7557D9',
      contrastText: 'white'
    },
    text: {
      primary: '#7557D9',
      secondary: '#6C6C6C'
    }
  },
  // Disable all shadows
  shadows: Array(25).fill('none') as Shadows,
  typography: {
    fontFamily: "'Nunito', sans-serif",
    button: {
      textTransform: 'none'
    },
    body1: {
      color: '#6C6C6C',
      fontSize: '1.5rem',
      lineHeight: 1.333
    },
    body2: {
      color: 'black',
      fontSize: '0.938rem',
      lineHeight: 1.333
    },
    caption: {
      color: '#7557D9',
      fontWeight: 900,
      fontSize: '1.313rem',
      lineHeight: 1.333,
      textTransform: 'uppercase'
    },
    subtitle1: {
      color: '#7557D9',
      fontWeight: 900,
      fontSize: '1.875rem',
      lineHeight: 1.333
    },
    title: {
      color: '#7557D9',
      fontFamily: "'Nunito', sans-serif",
      fontWeight: 900,
      fontSize: '2.813rem',
      lineHeight: 1.333
    },
    humongous: {
      color: '#7557D9',
      fontFamily: "'Nunito', sans-serif",
      fontWeight: 900,
      fontSize: '9.25rem',
      lineHeight: 1,
      background: '-webkit-linear-gradient(223.77deg, #AD90EE, #7557D9)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 768,
      laptop: 1024,
      desktop: 1200
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as any
      // Apparently this bug is still present https://github.com/mui-org/material-ui/issues/16846#issuecomment-518487434
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textAlign: 'center'
        },
        text: {
          color: 'black'
        },
        outlined: {
          borderWidth: '2px !important'
        },
        contained: {
          background: 'linear-gradient(223.77deg, #AD90EE, #7557D9)'
        },
        sizeSmall: {
          fontSize: '1.125rem',
          fontWeight: 700,
          lineHeight: '1.5rem',
          padding: '12px 30px',
          borderRadius: '9px'
        },
        sizeMedium: {
          fontSize: '1.25rem',
          fontWeight: 800,
          lineHeight: '1.7rem',
          padding: '18px 20px',
          borderRadius: '7px'
        },
        sizeLarge: {
          fontSize: '1.813rem',
          fontWeight: 800,
          lineHeight: '2.438rem',
          padding: '22px 38px',
          borderRadius: '12px'
        },
        containedSizeSmall: {
          minWidth: '10rem'
        }
      }
    }
  }
})

export const lightTheme = responsiveFontSizes(theme, {
  variants: [
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'overline',
    'subtitle1',
    'subtitle2',
    'title' as Variant,
    'humongous' as Variant
  ]
})

// Add typography variant
declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariants {
    title: React.CSSProperties
    humongous: React.CSSProperties
  }

  // allow configuration using `createTheme`
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariantsOptions {
    title?: React.CSSProperties
    humongous?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyPropsVariantOverrides {
    title: true
    humongous: true
  }
}

// Add custom breakpoints
declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface BreakpointOverrides {
    mobile: true
    tablet: true
    laptop: true
    desktop: true
  }
}
