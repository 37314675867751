import { SnackbarProvider } from 'notistack'
import React from 'react'

import Router from './components/router/Router'

const App = () => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
  >
    <Router />
  </SnackbarProvider>
)

export default App
