import { useTheme } from '@mui/material'
import React, { FC } from 'react'

import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'

type PageBaseProps = {
  hideFooterOnMobile?: boolean
  needsNavBar?: boolean
}

const PageBase: FC<PageBaseProps> = ({
  hideFooterOnMobile = false,
  needsNavBar = true,
  children
}) => {
  const theme = useTheme()

  return (
    <>
      {needsNavBar && <Navbar />}
      {children}
      <Footer
        sx={
          hideFooterOnMobile
            ? { [theme.breakpoints.down('tablet')]: { display: 'none' } }
            : {}
        }
      />
    </>
  )
}

export default PageBase
