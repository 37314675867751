import { Link, Theme, Typography, useTheme } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { NBSP } from '../../utils/constants'
import { Container } from './components'

type FooterProps = {
  className?: string
  sx?: SxProps<Theme>
}

const Footer: FC<FooterProps> = ({ className, sx }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Container className={className} sx={sx}>
      <Typography variant='body2'>{t('footer.copyright')}</Typography>
      <Typography variant='body2' sx={{ color: theme.palette.text.secondary }}>
        <Link
          color='inherit'
          underline='hover'
          href={t('footer.privacyPolicy.url')}
        >
          {t('footer.privacyPolicy.text')}
        </Link>
        {NBSP}|{NBSP}
        <Link
          color='inherit'
          underline='hover'
          href={t('footer.termsAndConditions.url')}
        >
          {t('footer.termsAndConditions.text')}
        </Link>
      </Typography>
    </Container>
  )
}

export default Footer
